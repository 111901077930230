import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Login from 'pages/login/Login';
import Providers from 'providers';
import { lazy, Suspense } from 'react';
import { disableLoginPage } from 'config';
import PageLoader from 'components/PreLoader/PageLoader';
import ProtectedRoute from 'ProtectedRoute';

const ProtectedApp = lazy(
  () => import('ProtectedApp' /* webpackChunkName: "ProtectedApp" */)
);

const isLoginPageDisabled = disableLoginPage === 'true';

function App() {
  return (
    <div className="App">
      <Providers>
        <Router>
          <Switch>
            {/* Prompts the user to select an IDP of choice that is used to login */}
            {!isLoginPageDisabled && (
              <Route path="/login">
                <Login />
              </Route>
            )}
            <ProtectedRoute path="*">
              <Suspense fallback={<PageLoader />}>
                <ProtectedApp />
              </Suspense>
            </ProtectedRoute>
          </Switch>
        </Router>
      </Providers>
    </div>
  );
}

export default App;
